module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"www.intercambio-estudiantil.es","short_name":"www.intercambio-estudiantil.es","start_url":"/","background_color":"#ffffff","theme_color":"#e96101","display":"standalone","icon":"src/images/emblem.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2cb04b445415ccab9861205f812104d1"},
    },{
      plugin: require('../node_modules/gatsby-source-akcms-v2/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"522426b32a4b6dbd67853cad427098737197f068b2877aaa","apiBaseUrl":"https://api.cms.austauschkompass.de/api/v1"},
    },{
      plugin: require('../node_modules/gatsby-source-akratings-high-school/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://www.schueleraustausch.de/api/v2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["xx"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"20","matomoUrl":"https://stats.schueleraustausch.net","siteUrl":"https://www.intercambio-estudiantil.es"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
