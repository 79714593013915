/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

/* To use Webfonts via fontsource (i.e. self-hosted)
   see: https://www.gatsbyjs.com/docs/how-to/styling/using-web-fonts/#self-host-google-fonts-with-fontsource)
*/
// import "@fontsource/inter/400.css"

// set ak-forwarded in sessionStorage (to be read by e.g. catalog_order_component)
export const onClientEntry = () => {
  if (!sessionStorage.getItem("ak-forwarded")) {
    let val = "direct"
    if (document.referrer.length > 0) {
      val = document.referrer
    }
    sessionStorage.setItem("ak-forwarded", val)
  }
}
